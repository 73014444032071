<template>
  <div
    class="modal fade"
    :id="`contact-info-video-modal`"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel"></h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            x
          </button>
        </div>
        <div class="modal-body">
          <video controls style="max-height: 60vh;">
            <source :src="selectedVideo.video" type="video/mp4" />
            Your browser does not support the video element.
          </video>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-danger"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <a
        href="#"
        class="accordion-button collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#media-video"
        aria-expanded="false"
        aria-controls="media-video"
      >
        <i class="ti ti-video me-2"></i> Videos
      </a>
    </h2>
    <div
      id="media-video"
      class="accordion-collapse collapse"
      data-bs-parent="#mediafile"
    >
      <div class="accordion-body row">
        <div v-for="(video, index) in videos" class="col-6" :key="index">
          <video controls style="max-width: 100%;">
            <source :src="video.video" type="video/mp4" />
            Your browser does not support the video element.
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
export default {
  props: {
    videos: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      visible: false,
      currentImageIndex: 0,
      selectedVideo : {},
    };
  },
  mounted() {
    // Initialize Fancybox on elements with the data-fancybox attribute
    Fancybox.bind("[data-fancybox]", {
      infinite: false, // optional: if you have multiple items, set to true if you want infinite scroll
      videoFormat: "video/mp4",
    });
  },
  methods: {},
};
</script>

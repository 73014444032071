<template>
  <div class="w-100">
    <div
      v-if="
        previousMessage &&
        previousMessage.created_at &&
        message.created_at.slice(0, 10) ==
          previousMessage.created_at.slice(0, 10)
      "
    ></div>
    <div v-else class="mb-2 w-100 gap-4 mt-4 d-flex justify-content-center">
      <hr
        style="
          height: 1px;
          width: 50%;
          background-color: gray;
          margin-top: -4px;
        "
      />
      <p class="align-self-center" style="min-width: fit-content; height: 20px">
        {{ message.created_at.slice(0, 10) }}
      </p>
      <hr
        style="
          height: 1px;
          width: 50%;
          background-color: gray;
          margin-top: -4px;
        "
      />
    </div>
    <div
      class="modal fade"
      :id="`exampleModal${message.id}`"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel"></h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              x
            </button>
          </div>
          <div class="modal-body">
            <img
              :src="message.image?.lg"
              class="w-100 h-100 rounded-1"
              style="max-height: 60vh"
              alt=""
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="chats chats-right mt-2" style="width: fit-content">
      <div
        v-if="message.body == 'Deleted Message'"
        :class="message.id"
        class="chat-content rounded p-1 position-relative"
      >
        <div class="chat-info">
          <div
            class="message-content text-danger bg-transparent border border-danger"
          >
            <p class="d-flex gap-2 align-items-center" style="min-width: 250px">
              <i style="font-size: 1.5rem" class="ti ti-trash"></i>
              <span>Message has been deleted.</span>
            </p>
          </div>
        </div>
      </div>

      <div
        v-else-if="message.error"
        :class="message.id"
        class="chat-content rounded p-1 position-relative d-flex align-items-center gap-2"
        style="margin-right: 0.8rem"
      >
        <button
          @click="removeErrorMessage"
          class="text-danger rounded-circle"
          style="padding: 4px 6px;border: 1px red solid;background-color: transparent;"
        >
          <i style="font-size: 1.5rem" class="ti ti-trash"></i>
        </button>
        <button
          @click="resendErrorMessage"
          class="text-white bg-info rounded-circle"
          style="padding: 4px 6px"
          :class="retrying.index == message.index && retrying.state ? 'animate-spin' : ''"
          :disabled="retrying.index == message.index && retrying.state"
        >
          <i style="font-size: 1.5rem" class="ti ti-reload"></i>
        </button>
        <div class="chat-info">
          <div class="message-content text-white bg-danger">
            <p class="d-flex gap-2 align-items-center" style="min-width: 200px;max-width: 300px; font-weight: 700;margin: 0;">
              <span v-if="message.type_verbose == 'AUDIO_MESSAGE_TEMP'"
                >Error sending audio!</span
              >
              <span v-else-if="message.type_verbose == 'IMAGE_MESSAGE_TEMP'"
                >Error sending image!</span
              >
              <span v-else-if="message.type_verbose == 'VIDEO_MESSAGE_TEMP'"
                >Error sending video!</span
              >
              <span v-else-if="message.type_verbose == 'DOCUMENT_MESSAGE_TEMP'"
                >Error sending document!</span
              >
              <span v-else>Error sending message!</span>
            </p>
            <p style="min-width: 200px;max-width: 300px; margin:0;">
              <audio v-if="message.type_verbose == 'AUDIO_MESSAGE_TEMP'" controls>
                <source :src="message.body" type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
              
              <video v-else-if="message.type_verbose == 'VIDEO_MESSAGE_TEMP'" controls width="200px">
                  <source :src="message.body" type="video/mp4" />
                  Your browser does not support the video element.
              </video>

              <span v-else-if="message.type_verbose == 'DOCUMENT_MESSAGE_TEMP'" class="overflow-hidden">
                <span class="file-icon">
                  <i class="ti ti-files"></i>
                </span>
                {{ message.body?.slice(0, 30) }}
              </span>

              <img v-else-if="message.type_verbose == 'IMAGE_MESSAGE_TEMP'" :src="replace(message.body)" alt="" class="w-100" />

              <span class="line-clamp-1" style="font-size: 0.875rem;" v-else>{{ message.body }}</span>
            </p>
          </div>
        </div>
      </div>
      <div
        v-else
        :class="message.id"
        class="chat-content rounded p-1 position-relative"
      >
        <div class="chat-profile-name text-end">
          <h6>
            {{ message.owner.name }}
            <i class="ti ti-circle-filled fs-7 mx-2"></i>
            <span class="chat-time">{{ formatTime(message.created_at) }}</span>
            <span class="msg-read success">
              <span v-if="sending && index == lastIndex"></span>
              <i v-else-if="message.seen == true || message.seen == undefined" class="ti ti-checks"></i>
              <i v-else class="ti ti-check"></i>
            </span>
          </h6>
        </div>
        <div class="chat-info">
          <chat-actions
            :messageId="message.id"
            @replyMessage="replyMessage"
            @editMessage="editMessage"
            @deleteMessage="deleteMessage"
            :myOwnMessage="myOwnMessage"
            :isEditable="message.type_verbose == 'MESSAGE'"
          ></chat-actions>
          <div class="message-content">
            <template v-if="message.reply_to">
              <div class="chat-profile-name">
                <h6>{{ message.reply_to.owner.name }}</h6>
              </div>
              <div
                @click="goToOriginalMessage(message.reply_to)"
                class="message-reply"
              >
                <p
                  style="font-size: 0.9rem"
                  v-if="message.reply_to.type_verbose == 'AUDIO_MESSAGE'"
                  class="d-flex items-center gap-2 align-items-center"
                >
                  <i class="ti ti-files"></i>
                  <span>Voice</span>
                </p>
                <p
                  style="font-size: 0.9rem"
                  v-else-if="message.reply_to.type_verbose == 'VIDEO_MESSAGE'"
                  class="d-flex items-center gap-2 align-items-center"
                >
                  <i class="ti ti-files"></i>
                  <span>Video</span>
                </p>
                <p
                  style="font-size: 0.9rem"
                  v-else-if="
                    message.reply_to.type_verbose == 'DOCUMENT_MESSAGE'
                  "
                  class="d-flex items-center gap-2 align-items-center"
                >
                  <i class="ti ti-files"></i>
                  <span>Document</span>
                </p>
                <img
                  v-else-if="message.reply_to.type_verbose == 'IMAGE_MESSAGE'"
                  class="rounded"
                  style="width: 50px; height: 70px"
                  :src="message.reply_to.image?.sm"
                  alt="image"
                />
                <p style="font-size: 0.9rem" v-else>
                  {{ message.reply_to.body }}
                </p>
              </div>
            </template>
            <template v-if="message.type_verbose == 'MESSAGE'">
              <div>
                <p style="margin-bottom: 4px;padding-bottom: 4px;">{{ translate ? message.translate.translate?.message : message.body }}</p>
                <button @click="translate = !translate" class="d-flex gap-1 w-100 justify-content-end btn align-items-center text-white" style="font-size: 0.75rem;margin: 0;padding: 0;">
                  <i style="margin: 0;padding: 0;" class="ti ti-settings"></i>
                  <span v-if="translate">See original</span>
                  <span v-else>Translate</span>
                </button>
              </div>
            </template>
            <template v-if="message.type_verbose == 'IMAGE_MESSAGE'">
              <button
                type="button"
                @click="showModal = message.image.lg"
                style="
                  background-color: transparent;
                  padding: 0;
                  margin: 0;
                  border: 0;
                "
                data-bs-toggle="modal"
                :data-bs-target="`#exampleModal${message.id}`"
              >
                <img
                  @click="showModal = message.image.lg"
                  :src="message.image.sm"
                  alt=""
                  width="200px"
                />
              </button>
            </template>
            <template v-if="message.type_verbose == 'IMAGE_MESSAGE_TEMP'">
              <div class="position-relative">
                <img :src="replace(message.body)" alt="" width="200px" />
                <div
                  class="position-absolute d-flex flex-column align-items-center"
                  style="
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: fit-content;
                  "
                >
                  <b-spinner></b-spinner>
                  <p style="width: 150px; text-align: center; margin-top: 4px">
                    Uploading image
                  </p>
                </div>
              </div>
            </template>
            <template v-if="message.type_verbose == 'AUDIO_MESSAGE'">
              <audio controls>
                <source :src="message.audio" type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </template>
            <template v-if="message.type_verbose == 'VIDEO_MESSAGE'">
              <video controls width="200px">
                <source :src="message.video" type="video/mp4" />
                Your browser does not support the video element.
              </video>
            </template>
            <template v-if="message.type_verbose == 'DOCUMENT_MESSAGE'">
              <div class="file-attach">
                <span class="file-icon">
                  <i class="ti ti-files"></i>
                </span>
                <div class="ms-2 overflow-hidden">
                  <h6 class="mb-1">{{ message.body?.slice(0, 30) }}</h6>
                  <p>{{ new Date(message.created_at).toLocaleTimeString() }}</p>
                </div>
                <a
                  @click.prevent="downloadFile(message.document)"
                  class="download-icon"
                >
                  <i class="ti ti-download"></i>
                </a>
              </div>
            </template>

            <template v-if="message.type_verbose == 'AUDIO_MESSAGE_TEMP'">
              <div
                class="d-flex justify-content-center align-items-center gap-2"
              >
                <b-spinner></b-spinner>
                <audio controls>
                  <source :src="message.body" type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            </template>

            <template v-if="message.type_verbose == 'VIDEO_MESSAGE_TEMP'">
              <div class="position-relative">
                <div
                  class="position-absolute d-flex flex-column align-items-center"
                  style="
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: fit-content;
                  "
                >
                  <b-spinner></b-spinner>
                  <p style="width: 150px; text-align: center; margin-top: 4px">
                    Uploading video
                  </p>
                </div>
                <video controls width="200px">
                  <source :src="message.body" type="video/mp4" />
                  Your browser does not support the video element.
                </video>
              </div>
            </template>
            <template v-if="message.type_verbose == 'DOCUMENT_MESSAGE_TEMP'">
              <div class="file-attach">
                <span class="file-icon">
                  <b-spinner></b-spinner>
                </span>
                <div class="ms-2 overflow-hidden">
                  <h6 class="mb-1">{{ message.body?.slice(0, 30) }}</h6>
                  <p>{{ new Date(message.created_at).toLocaleTimeString() }}</p>
                </div>
              </div>
            </template>

            <div class="emoj-group">
              <ul>
                <li class="emoj-action">
                  <a href="javascript:void(0);" @click="toggleEmojiList"
                    ><i class="ti ti-mood-smile"></i
                  ></a>
                  <div
                    :style="{ display: showEmojiList ? 'block' : 'none' }"
                    class="emoj-group-list"
                  >
                    <ul>
                      <li
                        @click="
                          () => {
                            if (reacted && myReaction.reaction == ':100:') {
                              removeReaction();
                            } else {
                              giveReact(':100:');
                            }
                          }
                        "
                      >
                        <a
                          :class="`${
                            reacted && myReaction.reaction == ':100:'
                              ? 'bg-primary'
                              : ''
                          } rounded-1 p-1`"
                          style="min-width: fit-content"
                          href="javascript:void(0);"
                          >💯</a
                        >
                      </li>
                      <li
                        @click="
                          () => {
                            if (reacted && myReaction.reaction == ':smile:') {
                              removeReaction();
                            } else {
                              giveReact(':smile:');
                            }
                          }
                        "
                      >
                        <a
                          :class="`${
                            reacted && myReaction.reaction == ':smile:'
                              ? 'bg-primary'
                              : ''
                          } rounded-1 p-1`"
                          style="min-width: fit-content"
                          href="javascript:void(0);"
                          >😄</a
                        >
                      </li>
                      <li
                        @click="
                          () => {
                            if (reacted && myReaction.reaction == ':heart:') {
                              removeReaction();
                            } else {
                              giveReact(':heart:');
                            }
                          }
                        "
                      >
                        <a
                          :class="`${
                            reacted && myReaction.reaction == ':heart:'
                              ? 'bg-primary'
                              : ''
                          } rounded-1 p-1`"
                          style="min-width: fit-content"
                          href="javascript:void(0);"
                          >❤️</a
                        >
                      </li>
                      <li class="add-emoj">
                        <a href="javascript:void(0);"
                          ><i class="ti ti-plus"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li @click="replyMessage(message.id)">
                  <a href="#"><i class="ti ti-arrow-forward-up"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="chat-profile-name">
          <!-- <p v-if="sending && index == lastIndex">sending</p> -->
          <span class="chat-time" v-if="message.edited"
            >(edited)<i class="ti ti-pencil"></i
          ></span>
        </div>
        <div
          class="emonji-wrap position-absolute"
          style="left: 24px; bottom: -6px"
        >
          <a
            v-if="myReaction && myReaction.reaction == ':100:'"
            href="javascript:void(0);"
          >
            <span
              style="
                background-color: #d7dbe2;
                border-radius: 50%;
                padding: 2px 4px;
              "
              >💯</span
            >
          </a>
          <a
            v-else-if="myReaction && myReaction.reaction == ':smile:'"
            href="javascript:void(0);"
            ><span
              style="
                background-color: #d7dbe2;
                border-radius: 50%;
                padding: 2px 4px;
              "
              >😄</span
            ></a
          >
          <a
            v-else-if="myReaction && myReaction.reaction == ':heart:'"
            href="javascript:void(0);"
            ><span
              style="
                background-color: #d7dbe2;
                border-radius: 50%;
                padding: 2px 4px;
              "
              >❤️</span
            ></a
          >
          <a
            v-if="otherReaction.reaction == ':100:'"
            href="javascript:void(0);"
          >
            <span
              style="
                background-color: #d7dbe2;
                border-radius: 50%;
                padding: 2px 4px;
              "
              >💯</span
            >
          </a>
          <a
            v-else-if="otherReaction.reaction == ':smile:'"
            href="javascript:void(0);"
            ><span
              style="
                background-color: #d7dbe2;
                border-radius: 50%;
                padding: 2px 4px;
              "
              >😄</span
            ></a
          >
          <a
            v-else-if="otherReaction.reaction == ':heart:'"
            href="javascript:void(0);"
            ><span
              style="
                background-color: #d7dbe2;
                border-radius: 50%;
                padding: 2px 4px;
              "
              >❤️</span
            ></a
          >
        </div>
      </div>

      <div class="chat-avatar" style="margin-top: 8px">
        <!-- <img
        :src="replace(message.owner.avatar.md)"
        class="rounded-circle dreams_chat"
        alt="image"
      /> -->
        <!-- <img
          src="@/assets/img/profiles/avatar-06.jpg"
          class="rounded-circle dreams_chat"
          alt="image"
        /> -->
        <img
          :src="message.owner?.avatar?.sm"
          class="rounded-circle dreams_chat"
          alt="image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import chatActions from "./chat-actions.vue";
export default {
  props: {
    message: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    retrying : {
      type : Object,
      required : false,
      default : () => {}
    },
    previousMessage: {
      type: Object,
      Required: false,
      default: null,
    },
    sending: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    lastIndex: {
      type: Number,
      default: 0,
    },
  },
  components: {
    chatActions,
  },
  name: "ChatMessagesMyView",
  data() {
    return {
      showEmojiList: false,
      reply_to: null,
      myOwnMessage: true,
      reactions: {},
      reacted: false,
      myReaction: {
        reaction: "",
        index: -1,
        id: -1,
      },
      otherReaction: {
        reaction: "",
        index: -1,
        id: -1,
      },
      token: localStorage.getItem("authtoken"),
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null,
      translate : false
    };
  },
  mounted() {
    this.differentiateReactions();
  },
  watch: {
    message : {
      handler(){
        this.differentiateReactions();
      },
      deep : true,
    }
  },
  methods: {
    differentiateReactions() {
      this.reactions = this.message.reactions?.data ?? {};
      this.reacted = this.message.reacted;
      if (this.reactions) {
        for (const reaction in this.reactions) {
          if (this.reactions[reaction] && this.reactions[reaction].length) {
            this.reactions[reaction].forEach((rt, i) => {
              if (rt.owner_id == this.user.id) {
                this.myReaction = {
                  index: i,
                  reaction: reaction,
                  id: rt.id,
                };
              } else {
                this.otherReaction = {
                  index: i,
                  reaction: reaction,
                  id: rt.id,
                };
              }
            });
          }
        }
      }
    },
    removeErrorMessage() {
      this.$emit("remove-error", this.message.index);
    },
    resendErrorMessage() {
      this.$emit("resend-message" , this.message.index)
    },
    removeReaction() {
      const id = this.myReaction.id; // supposed to be reaction id I gave;
      this.reacted = false;
      this.reactions[this.myReaction.reaction] = this.reactions[
        this.myReaction.reaction
      ].filter((rt, i) => i != this.myReaction.index);
      this.myReaction = {
        id: "",
        index: -1,
        reaction: "",
      };

      this.axios
        .delete(
          `${this.$config.APIURL}/api/messenger/threads/${this.message.thread_id}/messages/${this.message.id}/reactions/${id}`,
          {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then(() => {
          this.reacted = false;
          this.reactions[this.myReaction.reaction] = this.reactions[
            this.myReaction.reaction
          ].filter((rt, i) => i != this.myReaction.index);
          this.myReaction = {
            id: "",
            index: -1,
            reaction: "",
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    giveReact(react) {
      this.myReaction = {
        id: "",
        reaction: react,
        index: -1,
      };
      this.axios
        .post(
          `${this.$config.APIURL}/api/messenger/threads/${this.message.thread_id}/messages/${this.message.id}/reactions`,
          { reaction: react },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.token,
            },
          }
        )
        .then((res) => {
          const r = Object.keys(this.reactions).find((key) => key == react);
          if (r && (this.reactions[r] || this.reactions[r].length)) {
            this.reactions[r].push(res.data);
          } else if (r && !this.reactions[r]) {
            this.reactions[r] = [res.data];
          } else {
            this.reactions[react] = [res.data];
          }
          this.reacted = true;
          this.myReaction = {
            id: res.data.id,
            reaction: res.data.reaction,
            index: this.reactions[react].length - 1,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },

    goToOriginalMessage(message) {
      // console.log('going to original message - ' , message.body)
      let bubble = document.getElementsByClassName(message.id)[0];
      console.log(bubble);
      if (bubble) {
        bubble.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setTimeout(() => {
          bubble.classList.add("reply-message-animation");
        }, 500);
        setTimeout(() => {
          bubble.classList.remove("reply-message-animation");
        }, 2000);
      }
    },
    replace(url) {
      return url.replace(/&amp;/g, "&");
    },
    toggleEmojiList() {
      this.showEmojiList = !this.showEmojiList;
    },
    formatTime(dateTime) {
      const date = new Date(dateTime);
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    replyMessage(messageId) {
      this.$emit("reply-message", messageId);
    },
    editMessage(messageId) {
      this.$emit("edit-message", messageId);
    },
    deleteMessage(choice) {
      this.$emit("delete-message", choice);
    },
    downloadFile(link) {
      console.log(link);
      // const url = link.replace(/&amp;/g, "&");
      const anchor = document.createElement("a");
      anchor.href = link;
      anchor.download = true;
      anchor.target = "_blank";
      document.body.appendChild(anchor);
      anchor.click();
      anchor.remove();
    },
  },
};
</script>

<style scoped>
/* Your styles go here */
.reply-message-animation {
  animation: fade 2s infinite ease;
}
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
@keyframes fade {
  from {
    background-color: #b5a3f7;
  }
  to {
    background-color: transparent;
  }
}
.animate-spin {
  animation: spin infinite 0.8s linear;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
<template>
  <div class="chat-users-wrap">
    <div v-if="!threadsUsers">
      <div class="w-100 my-4 d-flex gap-2">
        <b-skeleton type="input" height="70px" width="100%"></b-skeleton>
      </div>
      <div class="w-100 my-4 d-flex gap-2">
        <b-skeleton type="input" height="70px" width="100%"></b-skeleton>
      </div>
      <div class="w-100 my-4 d-flex gap-2">
        <b-skeleton type="input" height="70px" width="100%"></b-skeleton>
      </div>
      <div class="w-100 my-4 d-flex gap-2">
        <b-skeleton type="input" height="70px" width="100%"></b-skeleton>
      </div>
    </div>
    <div
      v-else-if="
        threadsUsers &&
        !(searchResults.users?.length || searchResults.conversations?.length)
      "
      :id="`threadsScrollArea`"
    >
      <div class="chat-list" v-for="user in threadsUsers" :key="user.id">
        <button
          :to="`/chat/${user.resources.recipient.provider_alias}/${user.resources.recipient.provider_id}`"
          @click="
            () => {
              unbindChannel(user);
              this.$store.commit('setCurrentChat', user);
            }
          "
          class="chat-user-list"
          style="width: 100%; border: 0; outline: 0"
        >
          <div
            class="avatar avatar-lg me-2"
            :class="user.options?.online_status ? 'online' : 'offline'"
          >
            <!-- <img
              src="@/assets/img/profiles/avatar-01.jpg"
              class="rounded-circle border border-warning border-2"
              alt="image"
            /> -->
            <img
              :src="user.resources?.recipient?.avatar?.sm"
              class="rounded-circle border border-warning border-2"
              alt="image"
            />
          </div>
          <div class="chat-user-info">
            <div class="chat-user-msg">
              <h6
                style="text-align: left"
                :class="user.new_thread ? 'text-primary' : ''"
              >
                {{ user.name }}
              </h6>
              <p style="text-align: left">
                ({{ user.resources.recipient.provider_alias }})
              </p>
              <p
                style="
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  text-align: left;
                "
                :class="user.unread ? 'text-secondary' : ''"
              >
                <span
                  v-if="user.resources.latest_message.type_verbose == 'MESSAGE'"
                  >{{ user.resources.latest_message.body }}</span
                >
                <span
                  v-else-if="
                    user.resources.latest_message.type_verbose ==
                    'AUDIO_MESSAGE'
                  "
                  >Audio File</span
                >
                <span
                  v-else-if="
                    user.resources.latest_message.type_verbose ==
                    'VIDEO_MESSAGE'
                  "
                  >Video File</span
                >
                <span
                  v-else-if="
                    user.resources.latest_message.type_verbose ==
                    'DOCUMENT_MESSAGE'
                  "
                  >Document</span
                >
                <span
                  v-else-if="
                    user.resources.latest_message.type_verbose ==
                    'IMAGE_MESSAGE'
                  "
                  >Image</span
                >
              </p>
              <!-- <p><span class="animate-typing">is typing
                            <span class="dot"></span>
                            <span class="dot"></span>
                            <span class="dot"></span>
                            </span>
                        </p> -->
            </div>
            <div class="chat-user-time">
              <div class="chat-pin" v-if="user.unread_count > 0 && user.unread">
                <!-- <i class="ti ti-pin me-2"></i> -->
                <span class="count-message fs-12 fw-semibold">{{
                  user.unread_count
                }}</span>
              </div>
              <span class="time">{{
                formatTime(user.resources.latest_message.created_at)
              }}</span>
            </div>
          </div>
        </button>
        <!-- <div class="chat-dropdown">
          <a class="#" href="#" data-bs-toggle="dropdown">
            <i class="ti ti-dots-vertical"></i>
          </a>
          <ul class="dropdown-menu dropdown-menu-end p-3">
            <li>
              <a class="dropdown-item" href="#"
                ><i class="ti ti-box-align-right me-2"></i>Archive Chat</a
              >
            </li>
            <li>
              <a class="dropdown-item" href="#"
                ><i class="ti ti-heart me-2"></i>Mark as Favourite</a
              >
            </li>
            <li>
              <a class="dropdown-item" href="#"
                ><i class="ti ti-check me-2"></i>Mark as Unread</a
              >
            </li>
            <li>
              <a class="dropdown-item" href="#"
                ><i class="ti ti-pinned me-2"></i>Pin Chats</a
              >
            </li>
            <li>
              <a
                class="dropdown-item"
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#delete-chat"
                ><i class="ti ti-trash me-2"></i>Delete</a
              >
            </li>
          </ul>
        </div> -->
      </div>
      <div v-if="fetching" class="d-flex justify-content-center my-5 w-100">
        <b-spinner variant="primary" class="w-fit"></b-spinner>
      </div>
      <div
        :id="`fetchMoreThreadContainer`"
        v-else-if="
          threadsUsers && threadsUsers.length > 0 && !this.meta.final_page
        "
        class="my-5"
      >
      <button @click="fetchMoreThreads" style="gap:1rem;" class="w-100 btn btn-primary d-flex align-items-center justify-content-center">
        <span>Fetch more</span>
        <i class="ti ti-arrow-down"></i>
      </button>
    </div>
    </div>

    <h5 v-if="searchResults.conversations?.length" class="mt-3 mb-3">
      Search Results ({{ searchResults.conversations.length }})
    </h5>
    <div
      v-for="contact in searchResults.conversations"
      :key="contact.id"
      class="contact-user d-flex align-items-center justify-content-between"
      style="cursor: pointer"
    >
      <div
        class="d-flex align-items-center"
        @click="gotoChatPage(contact.provider_alias, contact.provider_id)"
      >
        <div class="avatar avatar-lg">
          <!-- <img :src="contact.avatar.sm" class="rounded-circle" alt="image" /> -->
          <img
            src="@/assets/img/profiles/avatar-01.jpg"
            class="rounded-circle"
            alt="image"
          />
        </div>
        <div class="ms-2">
          <h6>{{ contact.base.name }}</h6>
          <p>{{ contact.base.email }}</p>
        </div>
      </div>
    </div>

    <h5 v-if="searchResults.users?.length" class="mt-3 mb-3">
      Users ({{ searchResults.users.length }})
    </h5>
    <div
      v-for="user in searchResults.users"
      :key="user.id"
      class="contact-user d-flex align-items-center justify-content-between"
      style="cursor: pointer"
    >
      <div
        class="d-flex align-items-center"
        @click="gotoChatPage('user', user.id)"
      >
        <div class="avatar avatar-lg">
          <!-- <img :src="contact.avatar.sm" class="rounded-circle" alt="image" /> -->
          <img
            src="@/assets/img/profiles/avatar-01.jpg"
            class="rounded-circle"
            alt="image"
          />
        </div>
        <div class="ms-2">
          <h6>{{ user.name }}</h6>
          <p>{{ user.email }}</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "AllChatSidebar",
  props: {
    initialMeta: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    threadsUsers() {
      return this.$store.getters.threadsUsers;
    },
    searchUsers() {
      return this.$store.getters.searchThreadUsers;
    },
    currentChat() {
      return this.$store.getters.currentChat;
    },
    searchResults() {
      return this.$store.getters.searchResults;
    },
  },
  data() {
    return {
      meta: {},
      fetching: false,
      token: localStorage.getItem("authtoken"),
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null,
      channel: {},
    };
  },
  watch: {
    initialMeta() {
      this.meta = this.initialMeta;
    },
  },
  mounted() {
    const channel = this.$pusher.subscribe(
      "private-messenger.user." + this.user.id
    );
    this.channel = channel;
    if (this.$route.name == "index-page") {
      channel.bind("new.message", (data) => {
        console.log("new message");
        let threads = [];
        this.threadsUsers.forEach((thread) => {
          if (thread.id == data.thread_id) {
            const th = { ...thread };
            if (data.owner_id != this.user.id) {
              th.unread = true;
              th.unread_count++;
            }
            th.resources.last_message = data;
            th.resources.latest_message = data;
            threads.unshift(th);
          } else {
            threads.push(thread);
          }
        });
        this.$store.commit("setThreadsUsers", threads);
      });
    }
    channel.bind("new.thread", (data) => {
      console.clear();
      console.log("new thread - " , data);
      const newThread = {
        ...data.sender,
        ...data.thread,
        unread: true,
        unread_count: 1,
        new_thread: true,
        resources: {
          recipient: data.sender,
          latest_message: {
            id: 0,
            thread_id: data.thread.id,
            owner: data.sender,
            type_verbose: "MESSAGE",
            body: `New message.`,
            edited: false,
            reacted: false,
            embeds: false,
            created_at: data.thread.created_at,
          },
        },
      };
      let threads = [newThread, ...this.threadsUsers];
      this.$store.commit("setThreadsUsers", threads);
    });
  },

  methods: {
    async fetchMoreThreads() {
      this.fetching = true;
      var url = `${this.$config.APIURL}${this.meta.next_page_route}`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };
      try {
        const response = await this.axios.get(url, { headers });
        //save vuex store here
        let threads = [...this.threadsUsers , ...response.data.data];
        //make the threads list unique cauz there might be synthetic items
        const uniqueArray = threads.filter(
          (item, index, self) => self.findIndex(i => i.id === item.id) === index
        );
        this.$store.commit('setThreadsUsers' , uniqueArray)
        this.meta = response.data.meta;
        this.fetching = false;
        // this.$store.commit("setThreadsUsers", response.data.data);
        // this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching chat users:", error);
        this.fetching = false;
      }
    },
    formatTime(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },

    gotoChatPage(alias, id) {
      this.$router.push({ path: `/chat/${alias}/${id}` });
    },
    unbindChannel(user) {
      this.channel.unbind("new.message", () => {
        this.$router.push(
          `/chat/${user.resources.recipient.provider_alias}/${user.resources.recipient.provider_id}`
        );
      });
      this.$router.push(
        `/chat/${user.resources.recipient.provider_alias}/${user.resources.recipient.provider_id}`
      );
    },
  },
};
</script>
<template>
  <div v-if="false">
    <b-skeleton type="input" width="50%"></b-skeleton>
    <b-skeleton type="input" width="20%"></b-skeleton>
    <b-skeleton type="input" width="90%"></b-skeleton>
    <b-skeleton type="input" width="50%"></b-skeleton>
  </div>
  <template v-else v-for="(message, i) in messages" :key="message.id">
    <ChatMessagesFriendView
      :previousMessage="messages[i - 1]"
      :message="message"
      v-if="message.owner?.provider_id != myId"
      @replyMessage="replyMessage"
      :autoTranslate="autoTranslate"
    />
    <ChatMessagesMyView
      :previousMessage="messages[i - 1]"
      :message="message"
      v-if="message.owner?.provider_id == myId"
      @replyMessage="replyMessage"
      @removeError="removeErrorMessage"
      :lastIndex="messages.length - 1"
      :index="i"
      :sending="sending"
      @editMessage="editMessage"
      @deleteMessage="deleteMessage"
      @resendMessage="resendErrorMessage"
      :retrying="retrying"
    />
  </template>
  <div class="scroll-here" style="margin-top: 150px; opacity: 0">hi</div>
</template>

<script>
import ChatMessagesFriendView from "./chat-messages-friends-view.vue";
import ChatMessagesMyView from "./chat-messages-myview.vue";
export default {
  props: {
    messages: {
      type: Array,
      default: () => []
    },
    retrying : {
      type : Object,
      default : () => {}
    },
    sending : {
      type : Boolean,
      default : false
    },
    autoTranslate : {
      type : Boolean,
      default : false
    }
  },
  components: {
    ChatMessagesFriendView,
    ChatMessagesMyView
  },
  name: "ChatMessages",
  data() {
    return {
      myId: localStorage.getItem("ownerid"),
      name: []
    };
  },
  mounted() {
    if (this.messages && this.messages.length) {
      // let lastMessage = document.getElementsByClassName(this.messages[this.messages.length - 1].id)[0];
      const lastMessage = document.getElementsByClassName("scroll-here")[0];
      if (lastMessage) {
        lastMessage.scrollIntoView({
          block: "start"
        });
        setTimeout(() => lastMessage.remove(), 10);
      }
    }
  },

  methods: {
    resendErrorMessage(index) {
      this.$emit("resend-message" , index)
    },
    removeErrorMessage(index) {
      this.$emit("remove-error", index);
    },
    replyMessage(messageId) {
      this.$emit("reply-message", messageId);
    },
    editMessage(messageId) {
      this.$emit("edit-message", messageId);
    },

    deleteMessage(choice) {
      this.$emit("delete-message", choice);
    }
    // Your methods go here
  }
};
</script>

<style scoped>
/* Your styles go here */
</style>

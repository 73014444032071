<script>
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import ChatMessages from "./components/chat-messages.vue";
import axios from "axios";
import SoundWave from "./components/SoundWave.vue";
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    messagesLoading: {
      type: Boolean,
    },
    id: {
      type: String,
      required: true,
    },
    thread_id: {
      type: String,
      default: null,
    },
    messages: {
      type: Array,
      default: null,
    },
    alias: {
      type: String,
      required: true,
    },
    initialMeta: {
      type: Object,
    },
  },

  data() {
    return {
      showFetchMore: false,
      audioChunks: [],
      searchKey: "",
      searching: false,
      chatVisible: false,
      showEmojiList: false,
      showEmojiListOne: false,
      showReply: false,
      replyMessage: "",
      selectedChat: "All Chats",
      settings: {
        suppressScrollX: true,
      },
      localMessages: [],
      localid: this.id,
      token: localStorage.getItem("authtoken"),
      isRecording: false,
      pusher: this.$pusher,
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null,
      replyMessageBlock: {
        img_url: null,
      },
      ownerid: localStorage.getItem("ownerid"),
      currentMessageId: null,
      sending: false,
      retrying : {
        index : -1,
        state : false
      },
      page: 1,
      fetching: false,
      meta: {
        index: true,
        page_id: null,
        next_page_id: "",
        next_page_route: "",
        final_page: false,
        per_page: 0,
        results: 0,
        total: 0,
      },
      userId: this.$route.params.id,
      autoTranslate : false,
    };
  },
  created() {
    this.localMessages = this.messages;
    this.$watch("messages", function (newVal) {
      if (newVal) {
        this.localMessages = newVal;
        const scrollArea = document.getElementById("messageScrollArea");
        if (scrollArea) {
          scrollArea.scrollTop = scrollArea.height + 100;
        } else {
          console.log("no scroll");
        }
      }
    });
  },
  mounted() {
    this.meta = this.initialMeta;
    const channel = this.$pusher.subscribe(
      "private-messenger.user." + this.user.id
    );
    channel.bind("new.message", (data) => {
      const message = {
        ...data,
        seen: false,
      };
      console.log("new message - ", message);
      const chat = this.$store.getters.chatBythreadId(message.thread_id);
      if (chat) {
        let messages = [...chat.messages, message];
        this.$store.commit("setChats", {
          ...chat,
          messages: messages.filter((msg) => !msg.temporary),
        });
      }
      if (message.thread_id == this.thread_id) {
        this.localMessages.push(message);
        this.scrollHandle();
      }
      //rearrange side bar threads
      let threads = [];
      let threadFound = false;
      this.threadsUsers.forEach((thread) => {
        if (thread.id == message.thread_id) {
          threadFound = true;
          const th = { ...thread };
          th.unread = !(
            message.thread_id == this.thread_id ||
            message.owner_id == this.user.id
          );
          th.unread_count = !(
            message.thread_id == this.thread_id ||
            message.owner_id == this.user.id
          )
            ? th.unread_count + 1
            : th.unread_count;
          th.resources.last_message = message;
          th.resources.latest_message = message;
          threads.unshift(th);
        } else {
          threads.push(thread);
        }
      });
      if (!threadFound) {
        // console.log('add synthetic thread - ' , data)
        //no thread in current threads list, not in current pagination
        let thread = {
          id: data.thread_id,
          synthetic: true,
          name: `${data.owner.name}`,
          resources: {
            recipient: data.owner,
            latest_message: data,
          },
          options: {
            online_status: 1,
          },
          unread: true,
          unread_count: 1,
        };
        threads.unshift(thread);
      }
      this.$store.commit("setThreadsUsers", threads);
    });

    channel.bind("reaction.added", (data) => {
      console.log("new reaction - ", data.reaction, data);
      if (data.message.thread_id == this.thread_id) {
        let tempMessages = this.localMessages;
        this.localMessages = [];
        this.localMessages = tempMessages.map((msg) => {
          if (msg.id == data.message_id) {
            msg.reacted = true;
            if (
              msg.reactions &&
              msg.reactions.data &&
              msg.reactions.data[data.reaction]
            ) {
              msg.reactions.data[data.reaction].push(data);
            } else {
              msg.reactions = { data: {}, meta: {} };
              msg.reactions.data[data.reaction] = [data];
            }
            msg.reacted = true;
          }
          return msg;
        });
      } else {
        let threads = [];
        this.threadsUsers.forEach((thread) => {
          if (thread.id == data.message.thread_id) {
            const th = { ...thread };
            if (data.thread_id != this.thread_id) {
              th.unread = true;
            }
            th.resources.latest_message.body = `Reacted ${
              data.reaction == ":100:"
                ? " 💯 "
                : data.reaction == ":smile:"
                ? " 😄 "
                : data.reaction == ":heart:"
                ? " ❤️ "
                : ""
            } to your message`;
            th.resources.latest_message.type_verbose = "MESSAGE";
            threads.unshift(th);
          } else {
            threads.push(thread);
          }
        });
        this.$store.commit("setThreadsUsers", threads);
      }
    });

    channel.bind("thread.read", (data) => {
      console.log("thread read - ", data);
      if (data.thread_id == this.thread_id) {
        this.localMessages = this.localMessages
          ? this.localMessages.map((msg) => {
              msg.seen = true;
              return msg;
            })
          : [];
      }
      const chat = this.$store.getters.chatBythreadId(data.thread_id);
      if (chat) {
        let messages = chat.messages.map((msg) => {
          msg.seen = true;
          return msg;
        });
        this.$store.commit("setChats", {
          ...chat,
          messages: messages.filter((msg) => !msg.temporary),
        });
      }
    });

    document.querySelectorAll(".chat-user-list").forEach(function (element) {
      element.addEventListener("click", function () {
        if (window.innerWidth <= 992) {
          const showChat = document.querySelector(".chat-messages");
          if (showChat) {
            showChat.classList.add("show");
          }
        }
      });
    });
    document.querySelectorAll(".chat-close").forEach(function (element) {
      element.addEventListener("click", function () {
        if (window.innerWidth <= 992) {
          const hideChat = document.querySelector(".chat-messages");
          if (hideChat) {
            hideChat.classList.remove("show");
          }
        }
      });
    });
  },
  watch: {
    id(newVal) {
      const chat = this.$store.getters.chats(newVal);
      if (chat && chat.threadId) {
        this.$store.commit("setCurrentChat", {
          ...this.currentChat,
          resources: {
            ...this.currentChat.resources,
            recipient: chat.recipient,
          },
        });
      }
      this.fetching = false;
      this.meta = {
        index: true,
        page_id: null,
        next_page_id: "",
        next_page_route: "",
        final_page: false,
        per_page: 0,
        results: 0,
        total: 0,
      };
      this.localMessages = [];
    },
    initialMeta(val) {
      this.meta = val;
    },
    localMessages(newVal) {
      if (newVal && newVal.length > 0) {
        setTimeout(() => {
          this.showFetchMore = true;
          document
            .getElementById(`messageScrollArea`)
            .addEventListener("scroll", () => {
              if (
                this.isElementInView(`messageScrollArea`, `fetchMoreContainer`)
              ) {
                this.fetching = true;
                this.fetchOlderMessages();
              }
            });
        }, 100);
      }
    },
  },

  methods: {
    fetchOlderMessages() {
      this.fetching = true;
      let self = this;
      fetch(`${this.$config.APIURL}${self.meta.next_page_route}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const parent = this.$refs.scrollArea;
          const oldScrollHeight = parent.scrollHeight;
          if (data.data && data.data.length) {
            if (data.data[0].thread_id == this.thread_id) {
              this.localMessages = [
                ...data.data.reverse(),
                ...this.localMessages,
              ];
              this.meta = data.meta;
              this.$nextTick(() => {
                const newScrollHeight = parent.scrollHeight;
                parent.scrollTop = newScrollHeight - oldScrollHeight;
              });
              const chat = this.$store.getters.chatBythreadId(
                data.data[0].thread_id
              );
              console.log("storing - ", chat);
              this.$store.commit("setChats", {
                ...chat,
                messages: this.localMessages,
                meta: this.meta,
              });
            }
          }
          this.fetching = false;
        })
        .catch((error) => {
          self.fetching = false;
          console.error("Error:", error);
        });
    },
    isElementInView(scrollableDivId, elementId) {
      const scrollableDiv = document.getElementById(scrollableDivId);
      const targetElement = document.getElementById(elementId);

      if (!scrollableDiv || !targetElement) {
        return false;
      }

      const scrollableRect = scrollableDiv.getBoundingClientRect();
      const elementRect = targetElement.getBoundingClientRect();

      return (
        elementRect.top >= scrollableRect.top &&
        elementRect.bottom <= scrollableRect.bottom
      );
    },
    replyMessageFunction(messageId) {
      this.showReply = true;
      this.replyMessageBlock = this.localMessages.find(
        (message) => message.id === messageId
      );
      this.replyMessageBlock.id = messageId;
    },
    async startRecording() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        console.error(
          "MediaDevices API or getUserMedia method is not supported in this browser."
        );
        return;
      }

      try {
        this.mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.mediaRecorder = new MediaRecorder(this.mediaStream);
        this.audioChunks = [];

        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.audioChunks.push(event.data);
          }
        };

        this.mediaRecorder.start();
        this.isRecording = true;
      } catch (error) {
        console.error("Error accessing media devices.", error);
      }
    },
    stopRecording() {
      if (!this.mediaRecorder) {
        console.error("No media recorder found.");
        return;
      }
      this.mediaRecorder.stop();
      this.sending = true;
      // const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
      // const audioUrl = URL.createObjectURL(audioBlob);
      this.mediaRecorder.onstop = () => {
        this.isRecording = false;
        const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" });
        var newMessage = this.temporyMessage(
          "AUDIO_MESSAGE_TEMP",
          URL.createObjectURL(audioBlob)
        );
        this.localMessages.push(newMessage);
        let tempIndex = this.localMessages.length - 1;
        this.scrollHandle();
        const formData = new FormData();
        formData.append("audio", audioBlob, "recording.wav");
        formData.append("temporary_id", this.generateRandomString(36));
        // audio.play();
        var self = this;
        axios
          .post(
            `${this.$config.APIURL}/api/messenger/threads/${this.thread_id}/audio`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${this.token}`,
              },
            }
          )
          .then(() => {
            this.sending = false;
            self.localMessages = self.localMessages.filter(
              (msg, i) => i != tempIndex
            );
            // response.data.seen = response.data.thread_id == this.thread_id;
            // self.localMessages.push(response.data);
          })
          .catch((err) => {
            this.sending = false;
            this.localMessages = this.localMessages.map((msg, i) => {
              if (tempIndex == i) {
                msg.error = true;
                msg.index = tempIndex;
                msg.error_message = err.response?.data?.message;
                msg.file = audioBlob;
              }
              return msg;
            });
          });

        this.mediaStream.getTracks().forEach((track) => track.stop());
      };
      return false;
    },
    cancelRecording() {
      this.mediaRecorder.stop();
      this.audioChunks = [];
      this.isRecording = false;
    },
    generateRandomString(length) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    scrollHandle() {
      this.$nextTick(() => {
        const scrollArea = this.$refs.scrollArea;
        if (scrollArea) {
          scrollArea.scrollTop = scrollArea.scrollHeight;
          // scrollArea.$el.update(); // Ensure PerfectScrollbar is updated
        }
      });
    },
    uploadImage(event) {
      this.sending = true;
      const file = event.target.files[0];
      const reader = new FileReader();
      let tempIndex = -1;
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        var newMessage = this.temporyMessage("IMAGE_MESSAGE_TEMP", imageUrl);
        this.localMessages.push(newMessage);
        tempIndex = this.localMessages.length - 1;
        this.scrollHandle();
      };
      reader.readAsDataURL(file);
      if (!file) return;

      const formData = new FormData();
      formData.append("image", file);
      formData.append("temporary_id", this.generateRandomString(36));

      axios
        .post(
          `${this.$config.APIURL}/api/messenger/threads/${this.thread_id}/images`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.sending = false;
          if (response.status < 400) {
            // Handle the response data as needed
            this.localMessages = this.localMessages.filter((msg, i) => {
              if (i == tempIndex) {
                console.log("message found - ", i, msg);
              }
              return i != tempIndex;
            });
            // response.data.seen = response.data.thread_id == this.thread_id;
            // this.localMessages.push(response.data);
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((err) => {
          this.sending = false;
          this.localMessages = this.localMessages.map((msg, i) => {
            if (tempIndex == i) {
              msg.error = true;
              msg.index = tempIndex;
              msg.error_message = err.response?.data?.message;
              msg.file = file;
            }
            return msg;
          });
        });
    },
    uploadVideo(event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }
      this.sending = true;
      const reader = new FileReader();
      let tempIndex = -1;
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        // console.log(imageUrl);
        var newMessage = this.temporyMessage("VIDEO_MESSAGE_TEMP", imageUrl);
        this.localMessages.push(newMessage);
        tempIndex = this.localMessages.length - 1;
        this.scrollHandle();
      };
      reader.readAsDataURL(file);
      if (!file) return;

      const formData = new FormData();
      formData.append("video", file);
      formData.append("temporary_id", this.generateRandomString(36));

      axios
        .post(
          `${this.$config.APIURL}/api/messenger/threads/${this.thread_id}/videos`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.sending = false;
          if (response.status < 400) {
            console.log("Video uploaded successfully:", response.data);
            // Handle the response data as needed
            this.localMessages = this.localMessages.filter(
              (msg, i) => i != tempIndex
            );
            // response.data.seen = response.data.thread_id == this.thread_id;
            // this.localMessages.push(response.data);
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((err) => {
          this.sending = false;
          this.localMessages = this.localMessages.map((msg, i) => {
            if (tempIndex == i) {
              msg.error = true;
              msg.index = tempIndex;
              msg.error_message = err.response?.data?.message;
              msg.file = file;
            }
            return msg;
          });
        });
    },
    changeChat(chat) {
      this.selectedChat = chat;
    },
    temporyMessage(
      type,
      replyMessage,
      temp_id = "9d953bdb-4ddb-4538-8c2c-1ffbaa7479bf"
    ) {
      var user = JSON.parse(localStorage.getItem("user"));
      return {
        id: temp_id,
        temporary: true,
        seen: false,
        thread_id: this.thread_id || "9d69d1d8-a93d-433b-8848-32dc94d25c32",
        owner_id: this.ownerid,
        owner_type: "App\\Models\\User",
        owner: {
          name: "You",
          route: null,
          provider_id: this.ownerid,
          provider_alias: this.alias,
          base: {
            id: this.ownerid,
            name: "You",
            email: user.email,
            phone_no: user.phone_no,
            email_verified_at: null,
            phone_no_verified_at: null,
            user_type: "staff",
            active: "1",
            user_type_id: 1,
            chat_admin: 0,
            customer_id: null,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            stripe_id: null,
            pm_type: null,
            pm_last_four: null,
            trial_ends_at: null,
          },
          avatar: this.currentChat.resources?.recipient?.avatar,
        },
        type: 0,
        type_verbose: type,
        system_message: false,
        from_bot: false,
        body: replyMessage,
        edited: false,
        reacted: false,
        embeds: true,
        extra: null,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        reply_to: this.showReply ? this.replyMessageBlock : null,
        meta: {
          thread_id: this.thread_id || "9d69d1d8-a93d-433b-8848-32dc94d25c32",
          thread_type: 1,
          thread_type_verbose: "PRIVATE",
        },
      };
    },
    updateMessage() {
      var currentMessageId = this.currentMessageId;
      const payload = { message: this.replyMessage };
      axios
        .put(
          `${this.$config.APIURL}/api/messenger/threads/${this.thread_id}/messages/${currentMessageId}`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.status < 400) {
            console.log("Message updated successfully:", response.data);
            const message = this.localMessages.find(
              (message) => message.id === currentMessageId
            );
            message.body = this.replyMessage;
            message.edited = true;
            this.replyMessage = "";
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.currentMessageId = null;
    },
    submitForm() {
      if (this.isRecording) {
        this.stopRecording();
        return;
      }
      this.sending = true;
      var newMessage = this.temporyMessage("MESSAGE", this.replyMessage);
      this.localMessages.push(newMessage);
      const tempIndex = this.localMessages.length - 1;
      var message = this.replyMessage;
      this.replyMessage = "";
      this.scrollHandle();
      let payload, url;
      let self = this;
      if (this.thread_id) {
        const temporaryId = this.generateRandomString(36);
        payload = { message: message, temporary_id: temporaryId };
        if (this.showReply) {
          payload.reply_to_id = this.replyMessageBlock.id;
        }
        url = `${this.$config.APIURL}/api/messenger/threads/${this.thread_id}/messages`;
      } else {
        payload = {
          message: message,
          recipient_alias: this.alias,
          recipient_id: this.id,
        };
        url = `${this.$config.APIURL}/api/messenger/privates`;
      }
      this.showReply = false;
      axios
        .post(
          url,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          self.sending = false;
          if (response.status < 400) {
            this.localMessages = this.localMessages.filter(
              (msg, i) => i != tempIndex
            );
            if (!self.thread_id) {
              //check if the message is sent to a new thread
              let threads = [
                { ...response.data, unread: false, unread_count: 0 },
                ...self.threadsUsers,
              ];
              self.$store.commit("setThreadsUsers", threads);
              self.localMessages.push(response.data.resources.latest_message);
              self.$emit("thread-added", response.data);
            } else {
              // this.localMessages = this.localMessages.filter(
              //   (msg, i) => i != tempIndex
              // );
              // response.data.seen = response.data.thread_id == this.thread_id;
              //this.localMessages.push(response.data);
            }
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((err) => {
          console.log(err);
          this.sending = false;
          this.localMessages = this.localMessages.map((msg, i) => {
            if (i == tempIndex) {
              msg.error = true;
              msg.index = tempIndex;
              msg.error_message = "Error sending message.";
            }
            return msg;
          });
        });
    },
    retryImageUpload(message, index) {
      const formData = new FormData();
      formData.append("image", message.file);
      formData.append("temporary_id", this.generateRandomString(36));

      axios
        .post(
          `${this.$config.APIURL}/api/messenger/threads/${this.thread_id}/images`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.sending = false;
          if (response.status < 400) {
            // Handle the response data as needed
            this.localMessages = this.localMessages.filter(
              (msg, i) => i != index
            );
            // response.data.seen = response.data.thread_id == this.thread_id;
            // this.localMessages.push(response.data);
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((err) => {
          this.sending = false;
          this.localMessages = this.localMessages.map((msg, i) => {
            if (index == i) {
              msg.error = true;
              msg.index = index;
              msg.error_message = err.response?.data?.message;
              msg.file = message.file;
            }
            return msg;
          });
        }).finally(() => {
          this.retrying = {
            index : -1,
            state : false,
          }
        }).finally(() => {
          this.retrying = {
            index : -1,
            state : false,
          }
        });
    },
    retryVideoUpload(message , index) {
      const formData = new FormData();
      formData.append("video", message.file);
      formData.append("temporary_id", this.generateRandomString(36));

      axios
        .post(
          `${this.$config.APIURL}/api/messenger/threads/${this.thread_id}/videos`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.sending = false;
          if (response.status < 400) {
            console.log("Video uploaded successfully:", response.data);
            // Handle the response data as needed
            this.localMessages = this.localMessages.filter(
              (msg, i) => i != index
            );
            // response.data.seen = response.data.thread_id == this.thread_id;
            // this.localMessages.push(response.data);
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((err) => {
          this.sending = false;
          this.localMessages = this.localMessages.map((msg, i) => {
            if (index == i) {
              msg.error = true;
              msg.index = index;
              msg.error_message = err.response?.data?.message;
              msg.file = message.file;
            }
            return msg;
          });
        }).finally(() => {
          this.retrying = {
            index : -1,
            state : false,
          }
        });

    },
    retryAudioUpload(message , index) {
      const formData = new FormData();
      formData.append("audio", message.file);
      formData.append("temporary_id", this.generateRandomString(36));

      axios
        .post(
          `${this.$config.APIURL}/api/messenger/threads/${this.thread_id}/audio`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.sending = false;
          if (response.status < 400) {
            console.log("Audio uploaded successfully:", response.data);
            this.localMessages = this.localMessages.filter(
              (msg, i) => i != index
            );
            // response.data.seen = response.data.thread_id == this.thread_id;
            // this.localMessages.push(response.data);
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((err) => {
          this.sending = false;
          this.localMessages = this.localMessages.map((msg, i) => {
            if (index == i) {
              msg.error = true;
              msg.index = index;
              msg.error_message = err.response?.data?.message;
              msg.file = message.file;
            }
            return msg;
          });
        }).finally(() => {
          this.retrying = {
            index : -1,
            state : false,
          }
        });
    },
    retryDocumentUpload(message , index) {
      const formData = new FormData();
      formData.append("document", message.file);
      formData.append("temporary_id", this.generateRandomString(36));

      axios
        .post(
          `${this.$config.APIURL}/api/messenger/threads/${this.thread_id}/documents`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.sending = false;
          if (response.status < 400) {
            console.log("Document uploaded successfully:", response.data);
            // Handle the response data as needed
            this.localMessages = this.localMessages.filter(
              (msg, i) => i != index
            );
            // response.data.seen = response.data.thread_id == this.thread_id;
            // this.localMessages.push(response.data);
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((err) => {
          this.sending = false;
          this.localMessages = this.localMessages.map((msg, i) => {
            if (index == i) {
              msg.error = true;
              msg.index = index;
              msg.error_message = err.response?.data?.message;
              msg.file = message.file;
            }
            return msg;
          });
        }).finally(() => {
          this.retrying = {
            index : -1,
            state : false,
          }
        });

    },
    resendMessage(ms , index) {
      var message = ms.body;
      this.scrollHandle();
      let payload, url;
      let self = this;
      if (this.thread_id) {
        const temporaryId = this.generateRandomString(36);
        payload = { message: message, temporary_id: temporaryId };
        if (ms.reply_to && ms.reply_to.id) {
          payload.reply_to_id = ms.reply_to.id;
        }
        url = `${this.$config.APIURL}/api/messenger/threads/${this.thread_id}/messages`;
      } else {
        payload = {
          message: message,
          recipient_alias: this.alias,
          recipient_id: this.id,
        };
        url = `${this.$config.APIURL}/api/messenger/privates`;
      }
      axios
        .post(
          url,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          self.sending = false;
          if (response.status < 400) {
            this.localMessages = this.localMessages.filter(
              (msg, i) => i != index
            );
            if (!self.thread_id) {
              //check if the message is sent to a new thread
              let threads = [
                { ...response.data, unread: false, unread_count: 0 },
                ...self.threadsUsers,
              ];
              self.$store.commit("setThreadsUsers", threads);
              self.localMessages.push(response.data.resources.latest_message);
              self.$emit("thread-added", response.data);
            }
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((err) => {
          console.log(err);
          this.sending = false;
          this.localMessages = this.localMessages.map((msg, i) => {
            if (i == index) {
              msg.error = true;
              msg.index = index;
              msg.error_message = "Error sending message.";
            }
            return msg;
          });
        }).finally(() => {
          this.retrying = {
            index : -1,
            state : false,
          }
        });
    },
    resendErrorMessage(index) {
      this.retrying = {
        index : index,
        state : true,
      }
      const message = this.localMessages[index];
      console.log(message);
      switch (message.type_verbose) {
        case 'MESSAGE':
          this.resendMessage(message, index); 
          break;
        case 'IMAGE_MESSAGE_TEMP':
          this.retryImageUpload(message, index);
          break;
        case 'VIDEO_MESSAGE_TEMP':
          this.retryVideoUpload(message, index);
          break;
        case 'AUDIO_MESSAGE_TEMP':
          this.retryAudioUpload(message, index);
          break;
        case 'DOCUMENT_MESSAGE_TEMP':
          this.retryDocumentUpload(message, index);
          break;
        default:
          console.log('switch default - ' ,message)
          break;
      }
    },
    uploadAudio(event) {
      this.sending = true;
      const file = event.target.files[0];
      const reader = new FileReader();
      const rand = this.generateRandomString(36);
      let tempIndex = -1;
      reader.onload = (e) => {
        const audioUrl = e.target.result;
        console.log(audioUrl);
        var newMessage = this.temporyMessage(
          "AUDIO_MESSAGE_TEMP",
          audioUrl,
          rand
        );
        this.localMessages.push(newMessage);
        tempIndex = this.localMessages.length - 1;
        this.scrollHandle();
      };
      reader.readAsDataURL(file);
      if (!file) return;

      const formData = new FormData();
      formData.append("audio", file);
      formData.append("temporary_id", rand);

      axios
        .post(
          `${this.$config.APIURL}/api/messenger/threads/${this.thread_id}/audio`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.sending = false;
          if (response.status < 400) {
            console.log("Audio uploaded successfully:", response.data);
            this.localMessages = this.localMessages.filter(
              (msg, i) => i != tempIndex
            );
            // response.data.seen = response.data.thread_id == this.thread_id;
            // this.localMessages.push(response.data);
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((err) => {
          this.sending = false;
          this.localMessages = this.localMessages.map((msg, i) => {
            if (tempIndex == i) {
              msg.error = true;
              msg.index = tempIndex;
              msg.error_message = err.response?.data?.message;
              msg.file = file;
            }
            return msg;
          });
        });
    },
    uploadDocument(event) {
      this.sending = true;
      const file = event.target.files[0];
      const reader = new FileReader();
      let tempIndex = -1;
      reader.onload = () => {
        // const documentUrl = e.target.result;
        var newMessage = this.temporyMessage(
          "DOCUMENT_MESSAGE_TEMP",
          file.name
        );
        this.localMessages.push(newMessage);
        tempIndex = this.localMessages.length - 1;
        this.scrollHandle();
      };
      reader.readAsDataURL(file);
      if (!file) return;

      const formData = new FormData();
      formData.append("document", file);
      formData.append("temporary_id", this.generateRandomString(36));

      axios
        .post(
          `${this.$config.APIURL}/api/messenger/threads/${this.thread_id}/documents`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.sending = false;
          if (response.status < 400) {
            console.log("Document uploaded successfully:", response.data);
            // Handle the response data as needed
            this.localMessages = this.localMessages.filter(
              (msg, i) => i != tempIndex
            );
            // response.data.seen = response.data.thread_id == this.thread_id;
            // this.localMessages.push(response.data);
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((err) => {
          this.sending = false;
          this.localMessages = this.localMessages.map((msg, i) => {
            if (tempIndex == i) {
              msg.error = true;
              msg.index = tempIndex;
              msg.error_message = err.response?.data?.message;
              msg.file = file;
            }
            return msg;
          });
        });
    },
    toggleEmojiList() {
      this.showEmojiList = !this.showEmojiList;
    },
    toggleEmojiListOne() {
      this.showEmojiListOne = !this.showEmojiListOne;
    },
    showChat() {
      this.chatVisible = true;
    },
    hideChat() {
      this.chatVisible = false;
      this.searching = false;
    },

    closeReply() {
      this.showReply = false;
    },
    editMessage(messageId) {
      this.currentMessageId = messageId;
      var message = this.localMessages.find(
        (message) => message.id === messageId
      );
      this.replyMessage = message.body;
    },
    deleteMessage({ messageId, choice = "me" }) {
      // axios
      //   .delete(
      //     `${this.$config.APIURL}/api/messenger/threads/${this.thread_id}/messages/${messageId}`,
      //     {
      //       headers: {
      //         "Content-Type": "application/json",
      //         Authorization: `Bearer ${this.token}`,
      //       },
      //     }
      //   )
      //   .then((response) => {
      //     if (response.status < 400) {
      //       console.log("Message deleted successfully:", response.data);
      //       this.localMessages = this.localMessages.filter(
      //         (message) => message.id !== messageId
      //       );
      //     } else {
      //       console.error("Unexpected response:", response);
      //     }
      //   })
      //   .catch((error) => {
      //     if (error.response && error.response.data) {
      //       console.error("Error:", error.response.data);
      //     } else {
      //       console.error("Error:", error.message);
      //     }
      //   });
      const payload = { message: "Deleted Message", choice: choice };
      axios
        .put(
          `${this.$config.APIURL}/api/messenger/threads/${this.thread_id}/messages/${messageId}`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.status < 400) {
            console.log("Message deleted successfully:", response.data);
            this.localMessages = this.localMessages.map((message) => {
              if (message.id === messageId) {
                message.body = "Deleted Message";
              }
              return message;
            });
            this.replyMessage = "";
          } else {
            console.error("Unexpected response:", response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.currentMessageId = null;
    },
    removeErrorMessage(index) {
      this.localMessages = this.localMessages.filter((msg, i) => i != index);
    },
    replace(url) {
      return url.replace(/&amp;/g, "&");
    },
    searchMessages() {
      if (this.searchKey.length > 2) {
        //start searching
        this.searching = true;
      }
    },
  },
  components: {
    ChatMessages,
    SoundWave,
  },
  computed: {
    currentChat() {
      return this.$store.getters.currentChat;
    },
    threadsUsers() {
      return this.$store.getters.threadsUsers;
    },
  },
};
</script>

<template>
  <div class="chat chat-messages" id="middle">
    <div>
      <div class="chat-header">
        <div class="user-details">
          <div class="d-xl-none">
            <a class="text-muted chat-close me-2" href="#" ref="closeChat">
              <i class="fas fa-arrow-left"></i>
            </a>
          </div>
          <div
            class="avatar avatar-lg flex-shrink-0"
            :class="currentChat.options?.online_status ? 'online' : 'offline'"
          >
            <img
              :src="currentChat.resources?.recipient?.avatar?.sm"
              class="rounded-circle"
              alt="image"
              v-if="this.name || currentChat.name"
            />
            <b-skeleton type="avatar" v-else></b-skeleton>
          </div>
          <div
            class="ms-2 overflow-hidden"
            v-if="this.name || currentChat.name"
          >
            <h6>
              {{
                currentChat.name ? currentChat.name : this.name ? this.name : ""
              }}
            </h6>
            <span class="last-seen">{{
              this.name || currentChat.name
                ? currentChat.options?.online_status
                  ? "Online"
                  : currentChat.options?.last_active ?? "Offline"
                : ""
            }}</span>
          </div>
          <div v-else class="ms-2 overflow-hidden">
            <b-skeleton width="120px"></b-skeleton>
            <b-skeleton width="100px"></b-skeleton>
          </div>
        </div>
        <div class="chat-options">
          <ul>
            <a-tooltip placement="bottom">
              <template #title>Translate messages automatically</template>
              <li class="d-flex align-items-top gap-2">
                Auto translate
                <b-form-checkbox
                  @change="(e) => autoTranslate = e"
                  v-model="autoTranslate"
                  name="check-button"
                  class="mt-1"
                  switch
                >
                </b-form-checkbox>
              </li>
            </a-tooltip>
            <a-tooltip placement="bottom">
              <template #title>Search</template>
              <li>
                <a class="chat-search-btn" @click="showChat">
                  <i class="ti ti-search"></i>
                </a>
              </li>
            </a-tooltip>
            <!-- <a-tooltip placement="bottom">
              <template #title>Video Call</template>
              <li>
                <a
                  href="javascript:void(0)"
                  class="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#video-call"
                >
                  <i class="ti ti-video"></i>
                </a>
              </li>
            </a-tooltip>
            <a-tooltip placement="bottom">
              <template #title>Voice Call</template>
              <li>
                <a
                  href="javascript:void(0)"
                  class="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#voice_call"
                >
                  <i class="ti ti-phone"></i>
                </a>
              </li>
            </a-tooltip> -->
            <a-tooltip placement="bottom">
              <template #title>Contact Info</template>
              <li>
                <a
                  href="javascript:void(0)"
                  class="btn"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#contact-profile"
                >
                  <i class="ti ti-info-circle"></i>
                </a>
              </li>
            </a-tooltip>
            <li>
              <a class="btn no-bg" href="#" data-bs-toggle="dropdown">
                <i class="ti ti-dots-vertical"></i>
              </a>
              <ul class="dropdown-menu dropdown-menu-end p-3">
                <li>
                  <router-link to="/index" class="dropdown-item"
                    ><i class="ti ti-x me-2"></i>Close Chat</router-link
                  >
                </li>
                <!-- <li>
                  <a
                    href="#"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#mute-notification"
                    ><i class="ti ti-volume-off me-2"></i>Mute Notification</a
                  >
                </li>
                <li>
                  <a
                    href="#"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#msg-disapper"
                    ><i class="ti ti-clock-hour-4 me-2"></i>Disappearing
                    Message</a
                  >
                </li>
                <li>
                  <a href="#" class="dropdown-item"
                    ><i class="ti ti-clear-all me-2"></i>Clear Message</a
                  >
                </li>
                <li>
                  <a
                    href="#"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-chat"
                    ><i class="ti ti-trash me-2"></i>Delete Chat</a
                  >
                </li>
                <li>
                  <a
                    href="#"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#report-user"
                    ><i class="ti ti-thumb-down me-2"></i>Report</a
                  >
                </li>
                <li>
                  <a
                    href="#"
                    class="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#block-user"
                    ><i class="ti ti-ban me-2"></i>Block</a
                  >
                </li> -->
              </ul>
            </li>
          </ul>
        </div>
        <!-- Chat Search -->
        <div
          class="chat-search search-wrap contact-search"
          :class="{ 'visible-chat': chatVisible }"
        >
          <div
            class="d-flex form-control border-0 rounded-0 justify-content-end pb-2"
          >
            <span class="text-danger" style="cursor: pointer" @click="hideChat"
              ><i class="ti ti-x"></i
            ></span>
          </div>
          <form
            class="form-controlborder-0 rounded-0"
            @submit.prevent="searchMessages"
          >
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Search Contacts hey"
                v-model="searchKey"
                @keyup="searchMessages"
              />
              <span class="input-group-text" @click="hideChat"
                ><i v-if="!searching" class="ti ti-search"></i
                ><b-spinner small v-else label="Spinning"></b-spinner
              ></span>
            </div>
          </form>
          <div
            v-if="searching"
            class="px-1 py-4 form-control border-0 rounded-0"
            style="min-height: fit-content"
          >
            <div
              v-for="message in searchResults"
              :key="message.id"
              :class="message.id"
              class="d-flex gap-2 p-2 shadow-sm my-2"
            >
              <img
                src="@/assets/img/profiles/avatar-06.jpg"
                class="rounded-circle dreams_chat"
                alt="image"
                style="width: 36px; height: 36px"
              />
              <div class="flex-grow-1">
                <h6 style="font-size: 1.1rem; font-weight: 700">
                  {{ message.owner.name }}
                </h6>
                <p
                  style="font-size: 0.9rem"
                  v-if="(message.type_verbose = 'MESSAGE')"
                >
                  {{ message.body }}
                </p>
              </div>
            </div>
          </div>
          <div v-else class="px-1 py-4 form-control border-0 rounded-0"></div>
        </div>
        <!-- /Chat Search -->
      </div>
      <div class="chat-body chat-page-group">
        <div
          ref="scrollArea"
          class="scroll-areaone"
          :settings="settings"
          style="max-height: 80vh; overflow-y: scroll"
          :id="`messageScrollArea`"
          :key="thread_id"
        >
          <div class="messages">
            <div v-if="fetching" class="d-flex justify-content-center w-100">
              <b-spinner variant="primary" class="w-fit"></b-spinner>
            </div>
            <!-- <div v-else class="d-flex justify-content-center w-100">
              <button @click="fetchOlderMessages(thread_id)" class="btn btn-primary">Fetch More</button>
            </div> -->
            <div
              :id="`fetchMoreContainer`"
              v-else-if="
                localMessages &&
                localMessages.length > 0 &&
                !this.meta.final_page &&
                showFetchMore
              "
              style="width: 100%; height: 50px; opacity: 0"
            ></div>

            <!-- Display loading message until data is loaded -->
            <div class="w-100" v-if="messagesLoading">
              <div class="w-100 my-4 d-flex gap-2 justify-content-end">
                <b-skeleton
                  type="input"
                  height="100px"
                  width="90%"
                ></b-skeleton>
                <b-skeleton type="avatar"></b-skeleton>
              </div>
              <div class="w-100 my-4 d-flex gap-2 justify-content-end">
                <b-skeleton type="input" height="60px" width="50%"></b-skeleton>
                <b-skeleton type="avatar"></b-skeleton>
              </div>
              <div class="w-100 my-4 d-flex gap-2">
                <b-skeleton type="avatar"></b-skeleton>
                <b-skeleton type="input" height="60px" width="40%"></b-skeleton>
              </div>
              <div class="w-100 my-4 d-flex gap-2">
                <b-skeleton type="avatar"></b-skeleton>
                <b-skeleton
                  type="input"
                  height="100px"
                  width="60%"
                ></b-skeleton>
              </div>
              <div class="w-100 my-4 d-flex gap-2 justify-content-end">
                <b-skeleton type="input" height="60px" width="40%"></b-skeleton>
                <b-skeleton type="avatar"></b-skeleton>
              </div>
            </div>
            <template v-else>
              <div
                class="text-center my-2 w-100"
                v-if="localMessages.length == 0"
              >
                No Messages Found
              </div>
              <!-- Display messages once data is loaded -->
              <template v-else>
                <Chat-messages
                  :messages="localMessages"
                  @replyMessage="replyMessageFunction"
                  @editMessage="editMessage"
                  @deleteMessage="deleteMessage"
                  @removeError="removeErrorMessage"
                  @resendMessage="resendErrorMessage"
                  :sending="sending"
                  :retrying="retrying"
                  :autoTranslate="autoTranslate"
                ></Chat-messages>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-footer">
      <form class="footer-form">
        <div class="chat-footer-wrap">
          <div
            class="form-item d-flex me-1 align-items-center gap-2"
            :class="isRecording ? 'w-100' : ''"
          >
            <div class="d-flex align-items-center">
              <button
                v-if="isRecording"
                class="bg-danger btn btn-sm"
                @click="cancelRecording"
              >
                <i class="ti ti-x"></i>
                <span class="ms-1">Cancel</span>
              </button>
              <a v-else href="#" @click="startRecording" class="mt-1">
                <i class="ti ti-microphone"></i>
              </a>
            </div>
            <div
              v-if="isRecording"
              class="recording-indicator d-flex justify-content-center flex-grow-1"
            >
              <div
                v-if="isRecording"
                class="flex-grow-1 d-flex justify-content-center"
              >
                <sound-wave></sound-wave>
              </div>
            </div>
          </div>
          <div class="form-wrap">
            <div v-if="showReply" class="chats reply-chat d-flex">
              <div class="chat-avatar">
                <img
                  :src="replyMessageBlock.owner.avatar?.sm"
                  class="rounded-circle"
                  alt="image"
                />
              </div>
              <div class="chat-content">
                <div class="chat-profile-name">
                  <h6>
                    {{ this.name || currentChat.name }}
                    <i class="ti ti-circle-filled fs-7 mx-2"></i>
                  </h6>
                </div>
                <div class="chat-info">
                  <div class="message-content">
                    <template
                      v-if="replyMessageBlock.type_verbose == 'MESSAGE'"
                    >
                      <div
                        class="message-reply"
                        style="max-height: 100px; overflow-y: scroll"
                      >
                        {{ replyMessageBlock.body }}
                      </div>
                    </template>
                    <template
                      v-if="
                        replyMessageBlock.type_verbose == 'DOCUMENT_MESSAGE'
                      "
                    >
                      <div class="message-reply">
                        {{ replyMessageBlock.body }}
                      </div>
                    </template>
                    <template
                      v-if="replyMessageBlock.type_verbose == 'IMAGE_MESSAGE'"
                    >
                      <div class="message-reply">
                        <!-- audio_url: this.$config.APIURL + '/storage/threads/' + this.message.thread_id + '/audio/' -->
                        <img
                          :src="replyMessageBlock.image?.sm"
                          alt=""
                          width="150px"
                        />
                      </div>
                    </template>
                    <template
                      v-if="replyMessageBlock.type_verbose == 'AUDIO_MESSAGE'"
                    >
                      <div class="message-reply">
                        <audio controls>
                          <source
                            :src="replyMessageBlock.audio"
                            type="audio/mpeg"
                          />
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                    </template>
                    <template
                      v-if="replyMessageBlock.type_verbose == 'VIDEO_MESSAGE'"
                    >
                      <div class="message-reply">
                        <video controls width="150px">
                          <source
                            :src="replyMessageBlock.video"
                            type="video/mp4"
                          />
                          Your browser does not support the audio element.
                        </video>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <a href="#" @click="closeReply" class="close-replay">
                <i class="ti ti-x"></i>
              </a>
            </div>
            <textarea
              v-model="replyMessage"
              class="form-control"
              placeholder="Type Your Message"
              v-if="!isRecording"
              @keyup="
                (e) => {
                  if (e.key == 'Enter' && replyMessage.trim().length > 0) {
                    if (currentMessageId) {
                      updateMessage();
                    } else {
                      submitForm();
                    }
                  }
                }
              "
            ></textarea>
          </div>
          <!-- <div class="form-item emoj-action-foot">
            <a href="#" class="action-circle" @click="toggleEmojiListOne"
              ><i class="ti ti-mood-smile"></i
            ></a>
            <div
              class="emoj-group-list-foot down-emoji-circle"
              :style="{ display: showEmojiListOne ? 'block' : 'none' }"
            >
              <ul>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="@/assets/img/icons/emonji-02.svg" alt="Icon"
                  /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="@/assets/img/icons/emonji-05.svg" alt="Icon"
                  /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="@/assets/img/icons/emonji-06.svg" alt="Icon"
                  /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="@/assets/img/icons/emonji-07.svg" alt="Icon"
                  /></a>
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img src="@/assets/img/icons/emonji-08.svg" alt="Icon"
                  /></a>
                </li>
                <li class="add-emoj">
                  <a href="javascript:void(0);"><i class="ti ti-plus"></i></a>
                </li>
              </ul>
            </div>
          </div> -->

          <div class="form-item">
            <a href="#" data-bs-toggle="dropdown">
              <i class="ti ti-dots-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end p-3">
              <a
                href="#"
                class="dropdown-item"
                @click.prevent="$refs.imageInput.click()"
                ><i class="ti ti-camera-selfie me-2"></i>Image</a
              >
              <input
                type="file"
                ref="imageInput"
                name="files"
                id="files"
                class="d-none"
                @change="uploadImage"
              />
              <a
                href="#"
                class="dropdown-item"
                @click.prevent="$refs.videoInput.click()"
                ><i class="ti ti-video me-2"></i>Video</a
              >
              <input
                type="file"
                ref="videoInput"
                name="files"
                id="files"
                class="d-none"
                @change="uploadVideo"
              />
              <a
                href="#"
                class="dropdown-item"
                @click.prevent="$refs.audioInput.click()"
                ><i class="ti ti-music me-2"></i>Audio</a
              >
              <input
                type="file"
                ref="audioInput"
                name="files"
                id="files"
                class="d-none"
                @change="uploadAudio"
              />
              <a
                href="#"
                class="dropdown-item"
                @click.prevent="$refs.documentInput.click()"
                ><i class="ti ti-file me-2"></i>Document</a
              >
              <input
                type="file"
                ref="documentInput"
                name="files"
                id="files"
                class="d-none"
                @change="uploadDocument"
              />
            </div>
          </div>
          <div class="form-btn">
            <button
              v-if="currentMessageId"
              class="btn btn-secondary"
              type="button"
              @click.prevent="updateMessage"
            >
              <i class="ti ti-edit"></i>
            </button>
            <button
              v-else
              class="btn btn-primary"
              type="submit"
              @click.prevent="submitForm"
            >
              <i class="ti ti-send"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>


<style scoped>
.ping-animation {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
@keyframes ping {
  75%,
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}
</style>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import axios from "axios";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  data() {
    return {
      selectedChat: "All Chats",
      settings: {
        suppressScrollX: true,
      },
      userData: {},
      token: localStorage.getItem("authtoken"),
      owenrId: localStorage.getItem("ownerid"),
      maintoken: localStorage.getItem("token"),
      currentUserName: localStorage.getItem("currentname"),
      users: [],
      searchkeyword: "",
      searching : false,
      meta : {},
    };
  },
  created() {
    this.fetchUserData();
    this.fetchChatUsers();
  },
  computed: {
    threadsUsers() {
      return this.$store.getters.threadsUsers;
    },
  },
  methods: {
    scrollHanle() {},
    changeChat(chat) {
      this.selectedChat = chat;
      console.log(chat);
      ///get token and store it in local storage
    },
    changeProfile(token) {
      for (let i = 0; i < this.users.length; i++) {
        if (this.users[i].token == token) {
          localStorage.setItem("authtoken", token);
          localStorage.setItem("ownerid", this.users[i].ownerid);
          localStorage.setItem("currentname", this.users[i].name);
        }
      }
      this.$router.go();
    },
    submitForm() {
      this.$router.push("/chat");
    },
    fetchUserData() {
      axios
        .get(`${this.$config.APIURL}/api/user/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.maintoken,
          },
          // your data here
        })
        .then((response) => {
          this.userData = response.data;
          localStorage.setItem("currentname", this.userData.name);
          localStorage.setItem("authtoken", this.userData.token);
          localStorage.setItem("ownerid", this.userData.id);

          this.users.push({
            name: this.userData.name,
            ownerid: this.userData.id,
            token: this.userData.token,
          });
          for (let i = 0; i < this.userData.business_pages.length; i++) {
            this.users.push({
              name: this.userData.business_pages[i].name,
              ownerid: this.userData.business_pages[i].id,
              token: this.userData.business_pages[i].token,
            });
          }

          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    searchMessenger(e) {
      if (this.searchkeyword.length > 2) {
        console.log("searching" + this.searchkeyword);
        this.searching = true;
        // axios
        //   .get(
        //     `${this.$config.APIURL}/api/messenger/search/${this.searchkeyword}`,
        //     {
        //       headers: {
        //         "Content-Type": "application/json",
        //         Authorization: "Bearer " + this.maintoken,
        //       },
        //     }
        //   )
        //   .then((response) => {
        //     this.$store.commit("setSearchThreadUsers", response.data.data);
        //     this.searching = false;
        //   })
        //   .catch((error) => {
        //     console.error(error);
        //     this.searching = false;
        //   });
          axios
          .get(
            `${this.$config.APIURL}/api/v1/messenger/search_all?query=${this.searchkeyword}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.maintoken,
              },
            }
          ).then((res) => {
            this.$store.commit("setSearchResults" , res.data)
            this.searching = false;
          }).catch((err) => {
            console.log(err)
          })
      } else if(this.searchkeyword.length == 0) {
        this.changeChat('All Chats')
        // this.$store.commit("setSearchThreadUsers", []);
        this.$store.commit("setSearchResults" , {conversations : [] , users : []})
        this.searching = false;
      }
    },
    async fetchChatUsers() {
      var url = `${this.$config.APIURL}/api/messenger/threads`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      };
      try {
        const response = await axios.get(url, { headers });
        //save vuex store here
        this.meta = response.data.meta;
        this.$store.commit("setThreadsUsers", response.data.data);
      } catch (error) {
        console.error("Error fetching chat users:", error);
      }
    },
  },
  components: {
    PerfectScrollbar,
  },
};
</script>

<template>
  <div id="chats" class="sidebar-content active slimscroll">
    <perfect-scrollbar
      class="scroll-area"
      :settings="settings"
      @ps-scroll-y="scrollHanle"
    >
      <div class="slimscroll">
        <div class="chat-search-header">
          <div
            class="header-title d-flex align-items-center justify-content-between"
          >
            <div class="mb-3">
              <h3>Chats</h3>
            </div>
            <div class="mb-3">
              <select
                class="form-select"
                @change="changeProfile($event.target.value)"
                v-if="users.length > 1"
              >
                <option
                  v-for="(user, index) in users"
                  :key="index"
                  :value="user.token"
                  :selected="user.name === currentUserName"
                >
                  {{ user.name }}
                </option>
              </select>
            </div>
            <div class="d-flex gap-4 align-items-center mb-3">
              <a
                href="javascript:void(0);"
                data-bs-toggle="modal"
                data-bs-target="#new-chat"
                class="add-icon btn btn-primary p-0 d-flex align-items-center justify-content-center fs-16 me-2"
                ><i class="ti ti-plus"></i
              ></a>
              <div class="dropdown">
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="dropdown"
                  class="fs-16 text-default"
                >
                  <i class="ti ti-dots-vertical"></i>
                </a>
                <ul class="dropdown-menu p-3">
                  <li>
                    <a
                      class="dropdown-item"
                      href="javascript:void(0);"
                      data-bs-toggle="modal"
                      data-bs-target="#invite"
                      ><i class="ti ti-send me-2"></i>Invite Others</a
                    >
                    
                  </li>
                </ul>
              </div>
              <profile-menu></profile-menu>
            </div>
          </div>

          <!-- Chat Search -->
          <div class="search-wrap">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                name="search-input"
                id="search-input"
                placeholder="Search For Contacts or Messages"
                v-model="searchkeyword"
                @keyup="searchMessenger"
              />
              <span class="input-group-text">
                <i v-if="!searching" class="ti ti-search"></i>
                <b-spinner small v-else label="Spinning"></b-spinner>
              </span>
            </div>
          </div>
          <!-- /Chat Search -->
        </div>

        <div class="sidebar-body chat-body" id="chatsidebar">
          <!-- Left Chat Title -->
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h5 class="chat-title">{{ selectedChat }}</h5>
            <div class="dropdown">
              <a
                href="#"
                class="text-default fs-16"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><i class="ti ti-filter"></i
              ></a>
              <ul
                class="dropdown-menu dropdown-menu-end p-3"
                id="innerTab"
                role="tablist"
              >
                <li role="presentation">
                  <a
                    class="dropdown-item"
                    :class="{ active: selectedChat === 'All Chats' }"
                    href="#"
                    @click="changeChat('All Chats')"
                    >All Chats</a
                  >
                </li>
                <li role="presentation">
                  <a
                    class="dropdown-item"
                    :class="{ active: selectedChat === 'Favourite Chats' }"
                    href="#"
                    @click="changeChat('Favourite Chats')"
                    >Favourite Chats</a
                  >
                </li>
                <li role="presentation">
                  <a
                    class="dropdown-item"
                    :class="{ active: selectedChat === 'Pinned Chats' }"
                    href="#"
                    @click="changeChat('Pinned Chats')"
                    >Pinned Chats</a
                  >
                </li>
                <li role="presentation">
                  <a
                    class="dropdown-item"
                    :class="{ active: selectedChat === 'Archive Chats' }"
                    href="#"
                    @click="changeChat('Archive Chats')"
                    >Archive Chats</a
                  >
                </li>
                <li role="presentation">
                  <a
                    class="dropdown-item"
                    :class="{ active: selectedChat === 'Trash' }"
                    href="#"
                    @click="changeChat('Trash')"
                    >Trash</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <!-- /Left Chat Title -->

          <div class="tab-content" id="innerTabContent">
            <div
              v-if="selectedChat === 'All Chats'"
              class="tab-pane fade"
              :class="[
                'tab-pane',
                'fade',
                { 'show active': selectedChat === 'All Chats' },
              ]"
            >
              <all-chat-sidebar :initialMeta="meta"></all-chat-sidebar>
            </div>
            <div
              v-if="selectedChat === 'Favourite Chats'"
              class="tab-pane fade"
              :class="[
                'tab-pane',
                'fade',
                { 'show active': selectedChat === 'Favourite Chats' },
              ]"
            >
              <favourite-chats-sidebar></favourite-chats-sidebar>
            </div>
            <div
              v-if="selectedChat === 'Pinned Chats'"
              class="tab-pane fade"
              :class="[
                'tab-pane',
                'fade',
                { 'show active': selectedChat === 'Pinned Chats' },
              ]"
            >
              <pinned-chats-sidebar></pinned-chats-sidebar>
            </div>
            <div
              v-if="selectedChat === 'Archive Chats'"
              class="tab-pane fade"
              :class="[
                'tab-pane',
                'fade',
                { 'show active': selectedChat === 'Archive Chats' },
              ]"
            >
              <archive-chats-sidebar></archive-chats-sidebar>
            </div>
            <div
              v-if="selectedChat === 'Trash'"
              class="tab-pane fade"
              :class="[
                'tab-pane',
                'fade',
                { 'show active': selectedChat === 'Trash' },
              ]"
            >
              <trash-sidebar></trash-sidebar>
            </div>
          </div>
        </div>
        
      </div>
    </perfect-scrollbar>
  </div>
</template>



<script>
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      visible: false,
      currentImageIndex: 0,
    };
  },
  computed: {
    lightboxImages() {
     return this.images.map((image) => image.image?.lg);
    },
  },
  methods: {
    showLightbox(index) {
      this.currentImageIndex = index;
      this.visible = true;
    },
    replace(url) {
      return url.replace(/&amp;/g, "&");
    },
  },
};
</script>

<template>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <a
        href="#"
        class="accordion-button collapsed"
        data-bs-toggle="collapse"
        data-bs-target="#chatuser-collapse1"
        aria-expanded="false"
        aria-controls="chatuser-collapse1"
      >
        <i class="ti ti-photo-shield me-2"></i>Photos
      </a>
    </h2>
    <div
      id="chatuser-collapse1"
      class="accordion-collapse collapse"
      data-bs-parent="#mediafile"
    >
      <div class="accordion-body">
        <div class="chat-user-photo">
          <div class="chat-img contact-gallery">

            <div class="img-wrap" v-for="(img, index) in images" :key="index">
              <img :src="img.image?.lg" :alt="img.alt" @click="showLightbox(index)" />
              <div class="img-overlay">
                <a @click.prevent="showLightbox(index)">
                  <i class="ti ti-eye"></i>
                </a>
                <a href="#"><i class="ti ti-download"></i></a>
              </div>
            </div>
          </div>
          <div class="text-center">
            <a
              class="gallery-img view-all link-primary d-inline-flex align-items-center justify-content-center mt-3"
              @click.prevent="showLightbox(0)"
            >
              All Images<i class="ti ti-arrow-right ms-2"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Lightbox -->
    <vue-easy-lightbox
      :visible="visible"
      :imgs="lightboxImages"
      :index="currentImageIndex"
      @hide="visible = false"
    />
  </div>
</template>
